import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './Home/Home';
import TopMenu from './TopMenu';

export default class App extends Component {
  render() {
    return <div className="">
      <TopMenu/>

      <Router>
        <Route path="/" exact component={Home} />
      </Router>
    </div>
  }
}
