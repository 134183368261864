import React, { Component } from "react";

export default class TopMenu extends Component {
    render() {
        return <nav className="navbar navbar-dark bg-dark navbar-expand-md justify-content-md-center justify-content-start">
            <a className="navbar-brand d-md-none d-inline" href="">PKIO</a>
            <button className="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
                <ul className="navbar-nav mx-auto text-md-center text-left">
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item my-auto">
                        <a className="nav-link navbar-brand mx-0 d-none d-md-inline" href="/">PKIO</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Link</a>
                    </li>
                </ul>
                <ul className="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
                    <li className="nav-item"><a className="nav-link" href="https://www.linkedin.com/in/christiannhenriksen/"><i className="fab fa-linkedin"></i></a> </li>
                </ul>
            </div>
        </nav>

    }
}