import React, { Component } from "react";

export default class Home extends Component {
    render() {
        return <div className="m-4">
            <div className="card-columns">

                <div className="card bg-light border-dark">
                    <div className="card-body">
                        <h5 className="card-title">Lokalavisensfordele</h5>
                        <p className="card-text">...</p>
                        <p className="card-text text-center"><small className="text-muted">2019</small></p>
                    </div>
                </div>

            </div>
        </div>
    }
}